/* eslint-disable import/first */

/**
 * Workaround to make regenerator-runtime work on production.
 *
 * See https://stephencharlesweiss.com/unsafe-eval-regenerator-runtime
 * And https://github.com/facebook/regenerator/issues/378#issuecomment-765141043
 */

// eslint-disable-next-line no-undef, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(globalThis as any).regeneratorRuntime = undefined;

/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as any).global = window;
